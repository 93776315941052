<template>
  <div class="mt-4">
    <div class="vx-row">
      <div class="vx-col w-full">
        <ValidationErrors :errors="errors"/>
      </div>
    </div>
    <div class="vx-row mb-3" v-if="!idProyek">
      <div class="vx-col w-full">
        <div class="con-vs-alert con-vs-alert-warning con-icon">
          <div class="vs-alert con-icon">
            <i class="vs-icon notranslate icon-scale icon-alert feather icon-info null"></i>
            Pilih proyek terlebih dahulu pada Tab SPP Header.
          </div>
        </div>
      </div>
    </div>

    <div v-if="isJenisSpkNonUnit">
      <div class="vx-row mb-3">
        <div class="vx-col w-full">
          <!--foreach only 1 item because spk non unit-->
          <vs-button class="w-full"
                     :color="(calculateTotalBobot(item.itemsPekerjaan) !== 100 && item.itemsPekerjaan.length > 0) ? 'danger' : 'primary'"
                     v-for="(item, index) in data.items"
                     :disabled="!idProyek"
                     :key="index"
                     :type="item.itemsPekerjaan.length > 0 ? 'filled' : 'border'"
                     @click="chooseItemsPekerjaan(item.uuid)">
            <span v-if="item.itemsPekerjaan.length === 0">Pilih Item Pekerjaan</span>
            <span v-if="item.itemsPekerjaan.length > 0">{{ item.itemsPekerjaan.length }} Item Pekerjaan Dipilih</span>
          </vs-button>
        </div>
      </div>
    </div>

    <div v-if="isJenisSpkUnit">
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-1/2 w-full">
          <div class="flex flex-wrap justify-between items-center mb-1">
            <div class="mb-4 md:mb-0 mr-4">
              <div class="flex">
                <vs-button color="success" icon-pack="feather" icon="icon-plus" @click="addRow" class="py-2 px-3 mr-2" :disabled="!idProyek">Add</vs-button>
                <vs-button color="danger" icon-pack="feather" icon="icon-x" :disabled="data.items.length < 1" @click="clearRows" class="py-2 px-3">Clear</vs-button>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-col sm:w-1/2 w-full px-0">
          <vs-input icon-pack="feather" icon="icon-search" class="mb-4 md:mb-0 mr-4 float-right" placeholder="Cari" v-model="search"/>
        </div>
      </div>
      <vs-table :data="rowsItems" stripe class="fix-table-input-background">
        <template slot="thead">
          <vs-th class="whitespace-no-wrap">#</vs-th>
          <vs-th class="whitespace-no-wrap">Action</vs-th>
          <vs-th class="whitespace-no-wrap">No Unit</vs-th>
          <vs-th class="whitespace-no-wrap">Tipe Unit</vs-th>
          <vs-th class="whitespace-no-wrap">Item Pekerjaan</vs-th>
          <vs-th class="whitespace-no-wrap">Total Nilai</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
            <vs-td>{{ index + 1 }}</vs-td>
            <vs-td>
              <vs-button color="danger" class="py-2 px-3" type="filled" icon-pack="feather" icon="icon-x" size="small" @click="removeRow(item.uuid)"/>
            </vs-td>
            <vs-td>
              <div @click="choosePropertiUnit(item.uuid)">
                <vx-input-group>
                  <vs-input :value="item.no_unit" readonly/>
                  <template slot="append">
                    <div class="append-text btn-addon">
                      <vs-button type="filled" icon-pack="feather" icon="icon-search"/>
                    </div>
                  </template>
                </vx-input-group>
              </div>
            </vs-td>
            <vs-td>
              <vs-input class="w-full" v-model="item.nama_properti_tipe" readonly/>
            </vs-td>
            <vs-td>
              <vs-button :type="item.itemsPekerjaan.length > 0 ? 'filled' : 'border'"
                         :disabled="!item.id_properti_unit"
                         :color="(calculateTotalBobot(item.itemsPekerjaan) !== 100 && item.itemsPekerjaan.length > 0) ? 'danger' : 'primary'"
                         @click="chooseItemsPekerjaan(item.uuid)">
                {{ item.itemsPekerjaan.length }} dipilih
              </vs-button>
            </vs-td>
            <vs-td>
              <v-money class="w-full" theme="theme4" v-model="item.total_nilai"/>
            </vs-td>
          </vs-tr>
          <vs-tr>
            <vs-td></vs-td>
            <vs-td></vs-td>
            <vs-td></vs-td>
            <vs-td></vs-td>
            <vs-td></vs-td>
            <vs-td><p class="text-right">{{ grandTotalNilai | idr }}</p></vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>

    <div v-if="isJenisSpkOther">
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-1/2 w-full">
          <div class="flex flex-wrap justify-between items-center mb-1">
            <div class="mb-4 md:mb-0 mr-4">
              <div class="flex">
                <vs-button color="success" icon-pack="feather" icon="icon-plus" @click="addRow" class="py-2 px-3 mr-2" :disabled="!idProyek">Add</vs-button>
                <vs-button color="danger" icon-pack="feather" icon="icon-x" :disabled="data.items.length < 1" @click="clearRows" class="py-2 px-3">Clear</vs-button>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-col sm:w-1/2 w-full px-0">
          <vs-input icon-pack="feather" icon="icon-search" class="mb-4 md:mb-0 mr-4 float-right" placeholder="Cari" v-model="search"/>
        </div>
      </div>
      <vs-table :data="rowsItems" stripe class="fix-table-input-background">
        <template slot="thead">
          <vs-th class="whitespace-no-wrap">#</vs-th>
          <vs-th class="whitespace-no-wrap">Action</vs-th>
          <vs-th class="whitespace-no-wrap">Nama Item</vs-th>
          <vs-th class="whitespace-no-wrap">Satuan</vs-th>
          <vs-th class="whitespace-no-wrap">Qty</vs-th>
          <vs-th class="whitespace-no-wrap">Harga Satuan</vs-th>
          <vs-th class="whitespace-no-wrap">Total Nilai</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
            <vs-td>{{ index + 1 }}</vs-td>
            <vs-td>
              <vs-button color="danger" class="py-2 px-3" type="filled" icon-pack="feather" icon="icon-x" size="small" @click="removeRow(item.uuid)"/>
            </vs-td>
            <vs-td>
              <div @click="chooseItemsPengadaan(item.uuid)">
                <vx-input-group>
                  <vs-input :value="item.nama_item_pengadaan" readonly/>
                  <template slot="append">
                    <div class="append-text btn-addon">
                      <vs-button type="filled" icon-pack="feather" icon="icon-search"/>
                    </div>
                  </template>
                </vx-input-group>
              </div>
            </vs-td>
            <vs-td>
              <vs-input class="w-full" v-model="item.satuan" readonly/>
            </vs-td>
            <vs-td>
              <vs-input type="number" class="w-full" v-model="item.qty"/>
            </vs-td>
            <vs-td>
              <v-money class="w-full" v-model="item.harga"/>
            </vs-td>
            <vs-td>
              <v-money class="w-full" theme="theme4" :value="item.qty * item.harga" readonly/>
            </vs-td>
          </vs-tr>
          <vs-tr>
            <vs-td></vs-td>
            <vs-td></vs-td>
            <vs-td></vs-td>
            <vs-td></vs-td>
            <vs-td></vs-td>
            <vs-td></vs-td>
            <vs-td><p class="text-right">{{ grandTotalNilaiOther | idr }}</p></vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>

    <div class="vx-row float-right mt-5">
      <div class="vx-col w-full flex">
        <vs-button @click="prev" type="border" class="mr-3" icon-pack="feather" icon="icon-arrow-left">Prev</vs-button>
        <vs-button @click="next" icon-pack="feather" icon="icon-arrow-right" icon-after>Next</vs-button>
      </div>
    </div>

    <!--modals-->
    <vs-popup class="sm:popup-w-70 popup-content-no-padding animate-none"
              title="Pilih Unit"
              :active.sync="modalUnit.active">
      <PropertiUnit :selectable="true"
                    :externalFilter="filterPropertiUnit"
                    @selected="onSelectedModalUnit"/>
    </vs-popup>

    <!--modals other-->
    <vs-popup class="sm:popup-w-70 popup-content-no-padding animate-none"
              title="Pilih Item"
              :active.sync="modalItemsPengadaan.active">
      <ItemsPengadaan :selectable="true"
                    :externalFilter="filterItemPengadaan"
                    @selected="onSelectedModalItemsPengadaan"/>
    </vs-popup>

    <ItemsPekerjaanChooser :active.sync="modalItemsPekerjaanChooser.active"
                           :rowUuid="modalItemsPekerjaanChooser.rowUuid"
                           @selected="onSelectedModalItemsPekerjaanChooser"/>
  </div>
</template>

<script>
import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import constant from '@/constant/constant'
import VMoney from '@/views/components/v-money/VMoney'

export default {
  name: 'TabPekerjaan',
  props: {
    initData: { default: null, type: Object } // for edit
  },
  components: {
    VMoney,
    PropertiUnit: () => import('@/views/pages/master/properti-unit/PropertiUnit'),
    ItemsPekerjaanChooser: () => import('@/views/pages/proyek/spk/parts/ItemsPekerjaanChooser'),
    ItemsPengadaan: () => import('@/views/pages/master/item-pengadaan/ItemPengadaan'),
    ValidationErrors
  },
  computed: {
    errors () {
      return this.$store.state.proyek.spkAdd.errors.tabPekerjaan.messages
    },
    tabHeader () {
      return this.$store.state.proyek.spkAdd.tabHeader
    },
    jenisSpk () {
      return this.tabHeader.jenis_spk
    },
    isJenisSpkNonUnit () {
      return this.jenisSpk === constant.JENIS_SPK.NON_UNIT
    },
    isJenisSpkUnit () {
      return this.jenisSpk === constant.JENIS_SPK.UNIT
    },
    isJenisSpkOther () {
      return this.jenisSpk === constant.JENIS_SPK.OTHER
    },
    idProyek () {
      return this.tabHeader.id_proyek
    },
    filterPropertiUnit () {
      return this.tabHeader.id_proyek ? { id_proyek: this.tabHeader.id_proyek } : null
    },
    filterItemPengadaan () {
      return {
        group1: 'JASA'
      }
    },
    rowsItems () {
      const search = this.search
      const items = this.data.items
      if (search != null) {
        return _.filter(items, (item) => {
          return item.nama.toLowerCase().includes(search.toLowerCase())
        })
      }
      return items
    },
    grandTotalNilai () {
      return _.sumBy(this.data.items, item => parseFloat(item.total_nilai || '0'))
    },
    grandTotalNilaiOther () {
      return _.sumBy(this.data.items, item => parseFloat((item.qty * item.harga) || '0'))
    }
  },
  watch: {
    initData (newVal, oldVal) {
      if (newVal && !_.isEqual(newVal, oldVal)) {
        this.data = _.cloneDeep(newVal)
      }
    },
    jenisSpk (newVal) {
      this.clearRows()
      if (newVal === constant.JENIS_SPK.NON_UNIT) this.createInitialRowForSpkNonUnit()
    },
    idProyek (newVal) {
      this.clearRows()
      if (this.jenisSpk === constant.JENIS_SPK.NON_UNIT) this.createInitialRowForSpkNonUnit()
    },
    data: {
      deep: true,
      handler (value) {
        this.commitDataToStore(value)
      }
    }
  },
  data () {
    return {
      search: null,
      modalUnit: {
        rowUuid: null,
        active: false
      },
      modalItemsPekerjaanChooser: {
        rowUuid: null,
        active: false
      },
      modalItemsPengadaan: {
        rowUuid: null,
        active: false
      },
      data: {
        items: [],
        deletedIds: []
      }
    }
  },
  methods: {
    choosePropertiUnit (rowUuid) {
      this.modalUnit.rowUuid = rowUuid
      this.modalUnit.active = true
    },

    chooseItemsPekerjaan (rowUuid) {
      this.modalItemsPekerjaanChooser.rowUuid = rowUuid
      this.modalItemsPekerjaanChooser.active = true
    },

    chooseItemsPengadaan (rowUuid) {
      this.modalItemsPengadaan.rowUuid = rowUuid
      this.modalItemsPengadaan.active = true
    },

    onSelectedModalUnit (data) {
      // validate for same item
      if (_.find(this.data.items, item => item.id_properti_unit === data.id) !== undefined) {
        return this.notifyWarning('Item yang sama tidak dapat ditambahkan lagi.')
      }

      // check if same properti tipe
      const unitWithSameTipe = _.find(this.data.items, item => item.id_properti_tipe === data.id_properti_tipe)
      const itemsPekerjaan = unitWithSameTipe ? unitWithSameTipe.itemsPekerjaan : []

      const uuid = this.modalUnit.rowUuid
      const index = _.findIndex(this.data.items, item => item.uuid === uuid)
      this.data.items[index].id_properti_unit = data.id
      this.data.items[index].id_properti_tipe = data.id_properti_tipe
      this.data.items[index].no_unit = data.no_unit
      this.data.items[index].nama_properti_tipe = data.nama_properti_tipe
      this.data.items[index].itemsPekerjaan = itemsPekerjaan
      this.modalUnit.active = false
    },

    onSelectedModalItemsPekerjaanChooser (data) {
      const uuid = this.modalItemsPekerjaanChooser.rowUuid
      const index = _.findIndex(this.data.items, item => item.uuid === uuid)
      this.data.items[index].itemsPekerjaan = data.items
      this.saveDeletedIds(data.deletedIds)
    },

    onSelectedModalItemsPengadaan (data) {
      const uuid = this.modalItemsPengadaan.rowUuid
      const index = _.findIndex(this.data.items, item => item.uuid === uuid)
      this.data.items[index].id_item_pengadaan = data.id
      this.data.items[index].nama_item_pengadaan = data.nama
      this.data.items[index].satuan = data.satuan
      this.modalItemsPengadaan.active = false
    },

    addRow () {
      let row
      if (this.isJenisSpkUnit) {
        row = {
          uuid: uuid(),
          id_properti_unit: null,
          id_properti_tipe: null,
          itemsPekerjaan: [],
          total_nilai: null
        }
      }
      if (this.isJenisSpkOther) {
        row = {
          uuid: uuid(),
          id_item_pengadaan: null,
          qty: null,
          harga: null
        }
      }
      this.search = null
      this.data.items.unshift(row)
    },

    createInitialRowForSpkNonUnit () {
      this.data.items.push({
        uuid: uuid(),
        itemsPekerjaan: []
      })
    },

    removeRow (uuid) {
      const index = _.findIndex(this.data.items, item => item.uuid === uuid)
      const item = this.data.items[index]

      // save deleted ids
      if (this.isJenisSpkOther) {
        if (item.id) this.saveDeletedIds([item.id])
      } else {
        const ids = _.map(item.itemsPekerjaan, itemPekerjaan => {
          if (itemPekerjaan.old_id_spk_pekerjaan) return itemPekerjaan.old_id_spk_pekerjaan
        })
        this.saveDeletedIds(ids)
      }

      this.data.items.splice(index, 1)
    },

    clearRows () {
      this.data.items = []
    },

    saveDeletedIds (ids) {
      this.data.deletedIds = _.union(this.data.deletedIds, ids)
    },

    calculateTotalBobot (itemsPekerjaan) {
      return _.sumBy(itemsPekerjaan, item => (parseFloat(item.bobot) || 0))
    },

    commitDataToStore: _.debounce(function (value) {
      const payload = _.cloneDeep(value)
      this.$store.commit('proyek/spkAdd/SET_TAB_PEKERJAAN', payload)
    }, 500),

    prev () {
      this.$store.commit('proyek/spkAdd/SET_ACTIVE_TAB', 0)
    },

    next () {
      this.$store.commit('proyek/spkAdd/SET_ACTIVE_TAB', 2)
    }
  }
}
</script>
